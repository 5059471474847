import { indexOf } from "lodash";

const carObj = {
    // 挖掘机
    excavating_machinery: {
        // 履带挖掘机
        0: '/excavating_machinery',
        // 轮式挖掘机
        1: '/wheelExcavator',
        // 轮式挖掘机
        // 2: [
        //     '/wheelExcavator0/png',
        //     '/wheelExcavator/png',
        //     '/wheelExcavator2/png',
        //     '/wheelExcavator3/png',
        //     '/wheelExcavator4/png',
        //     '/wheelExcavator/png',
        // ],
    },
    // 铲土
    shovel_transport_machinery: {
        0: "/load",
       2 : '/bulldozing',
       3 : '/grader',
    },
    // 筑养路
    road_building_machinery: {
        0: "/paving",
        1: "/paving",
    },
    // 压实
    compaction_machinery: {
        0: "/pressureRoad",
        1: "/pressureRoad",
        2: "/pressureRoad",
        3: "/pressureRoad",
        4: "/pressureRoad",
        5: "/pressureRoad",
    },
    // 起重
    hoisting_machinery: {
        // 汽车起重机
        0: '/crane',
        // 塔式起重机
        4: '/pendant',
        // 履带起重机
        2: '/lifting',
    },
    // 高空作业
    aerial_work_machinery: {
        0: '/lift',
        // 剪叉式高空
        1: '/highAltitude',
    },
    // 混凝土
    concrete_machinery: {
        0: '/pumpTruck',
        3: '/stir',
    },
    // 打桩
    piling_machinery: {
        // 0: '/piling',
        1: "/rotaryDigging",
    },
    // 工业车辆
    industrial_vehicles: {
        1: '/trailer',
        3: '/trailer'
    },
    // 矿山
    mining_machinery: {
        10: '/broken'
    },
    // 桥梁
    bridge_machinery: [],
    // 港口
    port_machinery: [],
    // 环卫
    sanitation_machinery: [],
    // 隧道
    tunnel_machinery: [],
    // 附属工装
    auxiliary_tooling: [],
    // 动力系统
    dynamic_system: [],
    // 吊管
    diao_guan_ji: true,
    // 动力系统
    han_jie_gong_zuo_zhan: true,
    yi_dong_dian_zhan:  '/han_jie_gong_zuo_zhan',
    zhong_pin_jia_re_che:  '/han_jie_gong_zuo_zhan',
    new_energy_vehicles:  '/new_energy_vehicles',
}
export default function(type, val, status, icon) {
    let url = "";
    if (carObj[type] && typeof(carObj[type]) !== 'string' && carObj[type][val]) {
        if (status < 6 && status!= 5) {
            url = carObj[type][val] + (icon ? '-icon' : '') + (status ==  1 ? 2 : status);
        }else {
            url = carObj[type][val] + (icon ? '-icon' : '');
        }
    }else if(carObj[type] && carObj[type] === true) {
        if (status < 6 && status!= 5) {
            url = '/' + type + (icon ? '-icon' : '') + (status ==  1 ? 2 : status);
        }else {
            url = '/' + type + (icon ? '-icon' : '');
        }
    }else if(carObj[type] && typeof(carObj[type]) === 'string') {
        if (status < 6 && status!= 5) {
            url = carObj[type] + (icon ? '-icon' : '') + (status ==  1 ? 2 : status);
        }else {
            url = carObj[type] + (icon ? '-icon' : '');
        }
    }else {
        if (status < 6 && status!= 5) {
            url = '/excavating_machinery' + (icon ? '-icon' : '') + (status ==  1 ? 2 : status);
        }else {
            url = '/excavating_machinery' + (icon ? '-icon' : '');
        }
    }

    return require('@/assets/images/car' + url + '.png');
}